import _isNil from "lodash/isNil";
import _isPlainObject from "lodash/isPlainObject";
import _isFunction from "lodash/isFunction";
import _defaults from "lodash/defaults";
import _assign from "lodash/assign";
import * as d3Shape from "d3-shape";
import { Helpers, Data, Style } from "victory-core";

var checkForValidText = function (text) {
  if (text === undefined || text === null || _isFunction(text)) {
    return text;
  } else {
    return "".concat(text);
  }
};

var getColor = function (style, colors, index) {
  if (style && style.data && style.data.fill) {
    return style.data.fill;
  }

  return colors && colors[index % colors.length];
};

var getRadius = function (props, padding) {
  if (typeof props.radius === "number") {
    return props.radius;
  }

  return Math.min(props.width - padding.left - padding.right, props.height - padding.top - padding.bottom) / 2;
};

var getOrigin = function (props, padding) {
  var width = props.width,
      height = props.height;
  var origin = _isPlainObject(props.origin) ? props.origin : {};
  return {
    x: origin.x !== undefined ? origin.x : (padding.left - padding.right + width) / 2,
    y: origin.y !== undefined ? origin.y : (padding.top - padding.bottom + height) / 2
  };
};

var getSlices = function (props, data) {
  var padAngle = _isFunction(props.padAngle) ? 0 : props.padAngle;
  var layoutFunction = d3Shape.pie().sort(null).startAngle(Helpers.degreesToRadians(props.startAngle)).endAngle(Helpers.degreesToRadians(props.endAngle)).padAngle(Helpers.degreesToRadians(padAngle)).value(function (datum) {
    return datum._y;
  });
  return layoutFunction(data);
};

var getCalculatedValues = function (props) {
  var colorScale = props.colorScale;
  var styleObject = Helpers.getDefaultStyles(props, "pie");
  var style = Helpers.getStyles(props.style, styleObject, "auto", "100%");
  var colors = Array.isArray(colorScale) ? colorScale : Style.getColorScale(colorScale);
  var padding = Helpers.getPadding(props);
  var defaultRadius = getRadius(props, padding);
  var origin = getOrigin(props, padding);
  var data = Data.getData(props);
  var slices = getSlices(props, data);
  return _assign({}, props, {
    style: style,
    colors: colors,
    padding: padding,
    defaultRadius: defaultRadius,
    data: data,
    slices: slices,
    origin: origin
  });
};

var getSliceStyle = function (index, calculatedValues) {
  var style = calculatedValues.style,
      colors = calculatedValues.colors;
  var fill = getColor(style, colors, index);
  return _assign({
    fill: fill
  }, style.data);
};

var getLabelText = function (props, datum, index) {
  var text;

  if (datum.label) {
    text = datum.label;
  } else if (Array.isArray(props.labels)) {
    text = props.labels[index];
  } else {
    text = _isFunction(props.labels) ? props.labels : datum.xName || datum._x;
  }

  return checkForValidText(text);
};

var getLabelArc = function (radius, labelRadius, style) {
  var padding = style && style.padding || 0;
  var arcRadius = labelRadius || radius + padding;
  return d3Shape.arc().outerRadius(arcRadius).innerRadius(arcRadius);
};

var getLabelPosition = function (arc, slice, position) {
  var construct = {
    startAngle: position === "startAngle" ? slice.startAngle : slice.endAngle,
    endAngle: position === "endAngle" ? slice.endAngle : slice.startAngle
  };

  var clonedArc = _assign({}, slice, construct);

  return arc.centroid(clonedArc);
};

var getLabelOrientation = function (degree, labelPlacement) {
  if (labelPlacement === "perpendicular") {
    return degree > 90 && degree < 270 ? "bottom" : "top";
  } else if (labelPlacement === "parallel") {
    return degree >= 0 && degree <= 180 ? "right" : "left";
  }

  if (degree < 45 || degree > 315) {
    return "top";
  } else if (degree >= 45 && degree < 135) {
    return "right";
  } else if (degree >= 135 && degree < 225) {
    return "bottom";
  } else {
    return "left";
  }
};

var getTextAnchor = function (orientation) {
  if (orientation === "top" || orientation === "bottom") {
    return "middle";
  }

  return orientation === "right" ? "start" : "end";
};

var getVerticalAnchor = function (orientation) {
  if (orientation === "left" || orientation === "right") {
    return "middle";
  }

  return orientation === "bottom" ? "start" : "end";
};

var getBaseLabelAngle = function (slice, labelPosition, labelStyle) {
  var baseAngle = 0;

  if (labelPosition.angle !== undefined) {
    baseAngle = labelStyle.angle;
  } else if (labelPosition === "centroid") {
    baseAngle = Helpers.radiansToDegrees((slice.startAngle + slice.endAngle) / 2);
  } else {
    baseAngle = labelPosition === "startAngle" ? Helpers.radiansToDegrees(slice.startAngle) : Helpers.radiansToDegrees(slice.endAngle);
  }

  var positiveAngle = baseAngle < 0 ? 360 - baseAngle : baseAngle;
  return positiveAngle % 360;
};

var getLabelAngle = function (baseAngle, labelPlacement) {
  if (labelPlacement === "vertical") {
    return 0;
  }

  if (labelPlacement === "parallel") {
    return baseAngle > 180 && baseAngle < 360 ? baseAngle + 90 : baseAngle - 90;
  }

  return baseAngle > 90 && baseAngle < 270 ? baseAngle - 180 : baseAngle;
};

var getLabelProps = function (text, dataProps, calculatedValues) {
  var index = dataProps.index,
      datum = dataProps.datum,
      data = dataProps.data,
      slice = dataProps.slice,
      labelComponent = dataProps.labelComponent,
      theme = dataProps.theme;
  var style = calculatedValues.style,
      defaultRadius = calculatedValues.defaultRadius,
      origin = calculatedValues.origin,
      width = calculatedValues.width,
      height = calculatedValues.height;
  var labelRadius = Helpers.evaluateProp(calculatedValues.labelRadius, _assign({
    text: text
  }, dataProps));
  var labelPosition = Helpers.evaluateProp(calculatedValues.labelPosition, _assign({
    text: text
  }, dataProps)) || "centroid";
  var labelPlacement = Helpers.evaluateProp(calculatedValues.labelPlacement, _assign({
    text: text
  }, dataProps)) || "vertical";

  var labelStyle = _assign({
    padding: 0
  }, style.labels);

  var evaluatedStyle = Helpers.evaluateStyle(labelStyle, _assign({
    labelRadius: labelRadius,
    text: text
  }, dataProps));
  var labelArc = getLabelArc(defaultRadius, labelRadius, evaluatedStyle);
  var position = getLabelPosition(labelArc, slice, labelPosition);
  var baseAngle = getBaseLabelAngle(slice, labelPosition, labelStyle);
  var labelAngle = getLabelAngle(baseAngle, labelPlacement);
  var orientation = getLabelOrientation(baseAngle, labelPlacement);
  var textAnchor = labelStyle.textAnchor || getTextAnchor(orientation);
  var verticalAnchor = labelStyle.verticalAnchor || getVerticalAnchor(orientation);
  var labelProps = {
    width: width,
    height: height,
    index: index,
    datum: datum,
    data: data,
    slice: slice,
    orientation: orientation,
    text: text,
    style: labelStyle,
    x: Math.round(position[0]) + origin.x,
    y: Math.round(position[1]) + origin.y,
    textAnchor: textAnchor,
    verticalAnchor: verticalAnchor,
    angle: labelAngle
  };

  if (!Helpers.isTooltip(labelComponent)) {
    return labelProps;
  }

  var tooltipTheme = theme && theme.tooltip || {};
  return _defaults({}, labelProps, Helpers.omit(tooltipTheme, ["style"]));
};

export var getBaseProps = function (props, fallbackProps) {
  props = Helpers.modifyProps(props, fallbackProps, "pie");
  var calculatedValues = getCalculatedValues(props);
  var slices = calculatedValues.slices,
      style = calculatedValues.style,
      data = calculatedValues.data,
      origin = calculatedValues.origin,
      defaultRadius = calculatedValues.defaultRadius,
      labels = calculatedValues.labels,
      events = calculatedValues.events,
      sharedEvents = calculatedValues.sharedEvents,
      height = calculatedValues.height,
      width = calculatedValues.width,
      standalone = calculatedValues.standalone,
      name = calculatedValues.name,
      innerRadius = calculatedValues.innerRadius,
      cornerRadius = calculatedValues.cornerRadius,
      padAngle = calculatedValues.padAngle;
  var radius = props.radius || defaultRadius;
  var initialChildProps = {
    parent: {
      standalone: standalone,
      height: height,
      width: width,
      slices: slices,
      name: name,
      style: style.parent
    }
  };
  return slices.reduce(function (childProps, slice, index) {
    var datum = _defaults({}, data[index], {
      startAngle: Helpers.radiansToDegrees(slice.startAngle),
      endAngle: Helpers.radiansToDegrees(slice.endAngle),
      padAngle: Helpers.radiansToDegrees(slice.padAngle)
    });

    var eventKey = !_isNil(datum.eventKey) ? datum.eventKey : index;
    var dataProps = {
      index: index,
      slice: slice,
      datum: datum,
      data: data,
      origin: origin,
      innerRadius: innerRadius,
      radius: radius,
      cornerRadius: cornerRadius,
      padAngle: padAngle,
      style: getSliceStyle(index, calculatedValues)
    };
    childProps[eventKey] = {
      data: dataProps
    };
    var text = getLabelText(props, datum, index);

    if (text !== undefined && text !== null || labels && (events || sharedEvents)) {
      var evaluatedText = Helpers.evaluateProp(text, dataProps);
      childProps[eventKey].labels = getLabelProps(evaluatedText, _assign({}, props, dataProps), calculatedValues);
    }

    return childProps;
  }, initialChildProps);
};